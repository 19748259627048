<template>
  <div class="wrapper flex fd-c ai-c">
      <img class="banner" src="/img/ranking.png" alt="">
      <div class="box" ref="box">
          <div class="navbox" :class="fixed?'fixed':''" :style="{'background-color':fixed?'rgba(255, 255, 255,'+opacity+')':''}">
                <div class="nav flex ai-c">
                    <div class="nav-item" 
                    v-for="(item, index) in nav" 
                    @click="navClick"
                    :class="acitve==index&&'acitve'"
                    :key="index">{{item.name}}</div>
                </div>
            </div>
      </div>
      <div class="list flex fd-c ai-c">
          <div class="title">美食小吃</div>
          <template v-for="(item, index) in 10">
              <v-rankingGoods :goods="index" :isNumberHide="true" :key="index" />
          </template>
          
      </div>
  </div>
</template>

<script>
import rankingGoods from '@/components/rankingGoods'
export default {
  name: "rankingList",
  components:{
      'v-rankingGoods': rankingGoods
  },
  props:{},
  data(){
    return {
        nav: [{
            name: '美食小吃'
        },{
            name: '新晋良店'
        },{
            name: '咖啡茶品'
        },{
            name: '周末去哪玩'
        },{
            name: '亲子玩乐'
        }],
        acitve: 0,
        fixed: false,
        opacity: 1
    }
  },
  watch:{},
  computed:{},
  methods:{
      fixedNav() {
          var offsetTop = this.$refs.box.offsetTop
          var scrollTop = document.documentElement.scrollTop;
          this.opacity = (scrollTop-offsetTop)/40
          scrollTop >=offsetTop ? this.fixed = true : this.fixed = false
      },
      navClick(e){
          console.log(e);
      }
  },
  created(){},
  mounted(){ 
    window.addEventListener('scroll', this.fixedNav, false);
  },
  beforeDestroy() { // 在组件生命周期结束的时候销毁。
    window.removeEventListener('scroll', this.fixedNav, false)
  }
}
</script>
<style scoped>
.wrapper{
    width: 100%;
    min-height: 100vh;
    background-color: rgba(150, 213, 229, 1);
}
.banner{
    width: 100%;
    height: 2.27rem;
}
.fixed{
    position: fixed;
    top: 0;
    padding: 0 .4rem!important;
    border-radius: 0!important;
}
.box{
    width: 7.1rem;
    height: .74rem;
    margin-top: .18rem;
}
.navbox{
    top: 0;
    left: 0;
    z-index: 100;
    width: 6.7rem;
    height: .74rem;
    background: #FFFFFF;
    border-radius: .10rem;
    overflow: hidden;
    padding: 0 .2rem;
}
.nav{
    width: 100%;
    height: .74rem;
    overflow-y: scroll;
}
.nav .nav-item{
    position: relative;
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    white-space: nowrap;
    margin-right: .45rem;
}
.nav .acitve{
    font-size: .30rem;
    font-weight: 800;
    color: #F96F30;
    margin-right: .4;
}
.nav .acitve::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -.08rem;
    width: 100%;
    height: .04rem;
    background: #F96F30;
    border-radius: .02rem;
}
.list{
    margin-top: .35rem;
}
.list .title{
    width: 2.54rem;
    background-image: url(/img/2020@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    font-size: .34rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: .25rem;
}
</style>